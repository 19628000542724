import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import ExplorePage from './pages/ExplorePage';
import PtrQueryPage from './pages/PtrQueryPage';
import HomePage from './pages/HomePage';
import InfrastructureMappingPage from './pages/InfrastructureMappingPage';
import './App.css';

const trackingId = "G-54F9HWM7KK";

// Initialize Google Analytics globally
ReactGA.initialize(trackingId);

function App() {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, []);

    return (
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/explore" element={<ExplorePage />} />
                <Route path="/ptr-query" element={<PtrQueryPage />} />
                <Route path="/infrastructure-mapping" element={<InfrastructureMappingPage />} />
            </Routes>
    );
}

// The Router must wrap the App component
export default function Root() {
    return (
        <Router>
            <App />
        </Router>
    );
}
